:root {
    --toastify-toast-width: 420px;
    --toastify-toast-min-height: 44px;
}
html body {
    overflow: auto !important;
}
body {
   scrollbar-gutter: stable;
    --removed-body-scroll-bar-size: 0 !important;
}
.Toastify__toast-theme--light.Toastify__toast--success {
    color: #115E59;
    background-color: #F0FDFA;
    border:solid 1px #E5F3F2;
    border-radius: 8px;
    padding: 12px 16px;
}
.Toastify__toast-theme--light.Toastify__toast--success .Toastify__close-button {
    align-self: center;
    color: #101B4B;
    opacity: 1;
}
.Toastify__toast-theme--light.Toastify__toast--success .Toastify__toast-body {
   padding: 0;
}

.Toastify__toast-theme--light.Toastify__toast--error {
    color: #101B4B;
    background-color: #FFF1F1;
    border:solid 1px #FC5555;
    border-radius: 8px;
    padding: 12px 16px;
}

.Toastify__toast-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;
}
.Toastify__toast-body > div {
    overflow: hidden;
}
.Toastify__toast-icon {
    margin-top: 4px;
}

.Toastify__toast-container {
    margin-bottom: 54px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

.ql-editor ul > li::before {
    content: none !important;
}
.ql-editor ol, .ql-editor ul {
    padding-left: 0 !important;
    margin: 0;
}

.ql-editor > ul > li:first-child {
    padding-left: 1em !important;
}
.ql-editor > ul > li{
    padding-left: 1em !important;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 2em !important;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 3em !important;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 4em !important;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 5em !important;
}
.ql-snow.ql-container .ql-editor.ql-blank::before {
    font-size: 14px;
    /* italic style */;
    font-style: italic !important;
}
